//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  home: {
    hero: {
      headline: {
        part1: 'Bei uns sind Sie ',
        part2: 'GOLD',
        part3: 'RICHTIG',
      },
      subheadline: 'Wir heißen Sie in Bad Wörishofen in angenehmer und diskreter Atmosphäre willkommen.',
      purchaseCTA: 'Ankaufsportal',
    },
    text: {
      sections: [
        {
          headline: 'Unser Team ist vom Fach.',
          parts: [
            'Unsere erfahrenen Edelmetallspezialistinnen und -spezialisten leben und lieben ihren Beruf. Wir alle „schätzen“ im wahrsten Sinne des Wortes den täglichen und persönlichen Umgang mit unseren Kundinnen und Kunden sehr.',
          ],
        },
        {
          headline: 'Wir bieten Ihnen gerne Besonderes.',
          parts: [
            'Sie wollen Ihren Liebsten oder sich selbst eine besondere Freude bereiten? Darf es eine wertschätzende Aufmerksamkeit oder ein besonders wertvolles Schmuckstück für Ihre Liebsten oder für Sie selbst sein?',
            'Darf es ein außergewöhnlicher Ring, eine stylische Uhr, wertvoller Brillantschmuck oder ein exquisites Stück mit Geschichte sein? Wir beraten Sie stilsicher mit Herz, unserem Gespür und unserer Fachkompetenz.',
          ],
        },
        {
          headline: 'Wir sind Spezialist für Trauring-Gestaltung.',
          parts: [
            'Treffen Sie zuhause in Ruhe eine Vorauswahl über unseren <a style="color: rgb(202,177,126);" href="https://konfigurator.jakob-schmuck.de/configurator" target="_blank">Trauring Konfigurator</a>. Dann vereinbaren wir einen persönlichen Termin für die Endauswahl bei uns in Bad Wörishofen oder direkt bei Ihnen.',
          ],
        },
        {
          headline: 'Wir erwerben Wertvolles.',
          parts: [
            'Profitieren Sie von den aktuell sehr hohen Goldpreisen und steigenden Rohstoffkursen!',
            'Die Bewertung ist für Sie völlig kostenfrei und begründet keine Verpflichtung zum Verkauf. Wir stehen für 100% Diskretion und direkte Auszahlung in bar oder auf Ihr Konto. Gerne können Sie auch Schmuck gegen Investmentgold tauschen.',
          ],
        },
        {
          headline: 'Schenken Sie uns Ihr Vertrauen.',
          parts: ['Besuchen Sie uns gerne in unserem Fachgeschäft im „Kur-Eck“ direkt beim Kurhaus in Bad Wörishofen.', 'Wir freuen uns auf Sie!'],
        },
      ],
      signature1: 'Monika und Roman N. Demirel',
      signature2: 'im Namen Ihres BAYERN-GOLD24 Teams',
    },
    chart: {
      headline: 'Aktuelle Preise',
    },
  },

  weddingRingConfigurator: {
    title: 'Simon & Söhne – unsere Trauring-Spezialisten',
  },

  goldAlloys: {
    title: 'Zusammensetzung von Edelmetall-Legierungen:',
    overview: 'Übersicht:',
  },

  contact: {
    title: 'Sie wollen mit uns in Kontakt treten? Wir freuen uns auf Sie!',
  },

  servicesSection: {
    title: 'Unsere Services',
    moreLabel: 'mehr',
    lessLabel: 'weniger',
    outro: [
      'Sie sind an einem Angebot für Ihre Wertsachen und Schätze interessiert?',
      'Besuchen Sie von zu Hause aus unser <a href="https://ankauf.bayern-gold24.de/" target="_blank">Ankaufportal</a>. Dort werden Sie einfach durchgeleitet. Oder kommen Sie zu uns nach Bad Wörishofen.',
    ],
    services: {
      jewelry: {
        headline: 'Schmuck',
        text: 'Was tun mit altem oder aus der Mode geratenem Goldschmuck aus Eigenbesitz oder einer Erbschaft?',
        moreText: [
          'Sie sind unsicher wegen Zusammensetzung oder Goldanteil der Stücke? Dafür sind wir mit unserer Expertise Ihr vertrauenswürdiger Ansprechpartner. Wir analysieren Ihre Schätze fachgerecht und zahlen einen fairen, tagesaktuellen Preis für den enthaltenen Goldanteil. Das Angebot gilt ebenso für Silber- oder Platinschmuck.',
          'Bringen Sie Ihren Schmuck gerne zu uns so wie er ist. Ob die Stücke beschädigt oder zu Bruch gegangen sind (Bruchgold), nicht dem Zeitgeist entsprechen, spielt keine Rolle. Bringen Sie uns gerne vom Goldschmied eingeschmolzene Edelmetalle oder mit Steinen besetzte Schmuckstücke.',
        ],
      },
      diamant: {
        headline: 'Diamant',
        text: 'Bieten Sie uns gerne jede Art von Diamanten an, unabhängig von Größe, Zertifizierung, gebrochen oder unversehrt.',
        moreText:
          'Jeder Diamant ist wertvoll. Es handelt sich um eine knappe Ressource. Zudem ist die Nachfrage nach Diamatschmuck und Anlagediamanten nach wie vor groß. Wir prüfen und bewerten diese sorgfältig für einen fairen Handel.',
      },
      watches: {
        headline: 'Uhren',
        text: 'Sie haben eine Uhr geerbt? Oder Sie wollen sich von einer wertvollen Uhr trennen?',
        moreText: [
          'Wir prüfen den Zustand Ihrer Uhr, ermitteln den fairsten Preis für Ihr gutes Stück. Wir unterstützen Sie beim Verkauf. Von Anfang bis Ende. Unser Angebot erfolgt schnellstmöglich, ebenso wie die Vergütung und Abwicklung des Ankaufs.',
          'Lassen Sie sich für eine neue Uhr von unserem Online-Katalog von <a href="https://www.jacques-lemans.com/katalog/index.php?catalog=bayern-gold-24" target="_blank">Jacques Lemans</a> inspirieren!',
        ],
      },
      investmentGold: {
        headline: 'Investment Gold',
        text: 'Wollen Sie sich von Goldbarren oder Gold- oder Silbermünzen trennen?',
        moreText: [
          'Wir kaufen Ihre gebrauchten Goldbarren gerne zum aktuellen Tageskurs des Edelmetalls an, unabhängig von Prägeanstalt oder Bank, die diese gestempelt hat.',
          'Sie können kein Zertifikat finden? Auch in diesem Fall finden wir eine gute Lösung.',
          'Wir analysieren den Feingoldgehalt in unserem Labor. Nach der Ermittlung des Goldanteils und des Gewichts erhalten Sie unser faires Angebot.',
          'Ob Goldmünzen wie Maple Leaf als 1 Unzen-Münze, Chinesischer Panda oder der Krügerrand oder alte Umlaufmünzen wie z.B. Deutsche Goldmark, Reichsgoldmünzen oder Silbermünzen wie den amerikanischer Silberdollar, Olympiamünzen – wir kaufen gerne jegliche Edelmetallmünzen an.',
          'Gedenkmünzen und Sonderprägungen bewerten wir mit unserem numismatischen Sachverstand und machen Ihnen ein angemessenes Angebot.',
        ],
      },
      numismatik: {
        headline: 'Numismatik',
        text: 'Sie haben besondere Sammlermünzen in Ihrem Bestand, deren Wert Sie nicht kennen?',
        moreText: [
          'Roman Demirel bildet sich immer wieder fort, damit Sie sicher sein können, eine seriöse Expertise zu erhalten',
          'Das Herkunftsland der Münzen, die Präge-/ Scheideanstalt oder Bank sind irrelevant. Es geht in diesen besonderen Fällen ist nicht nur der Gold- oder Silberanteil entscheidend für die Preisfindung. Es gilt den Sammlerwert mit numismatischem Sachverstand zu ermitteln. ',
        ],
      },
      toothGold: {
        headline: 'Zahngold & Edelmetallgegenstände',
        text: 'Zahngold ist oft wertvoll. Versilberungen ebenso. Und Zinn ist ein wertvolles Recyclinggut.',
        moreText: [
          '<strong>„Zahngold“</strong> besteht meist aus verschiedenen Edelmetallen in unterschiedlicher Zusammensetzung. Der Goldanteil bestimmt den Wert des Zahngoldes. Achtung: Es gibt „Zahngold“, dass <strong><u>kein</u></strong> Edelmetall enthält. Daher hat es leider keinen Ankaufswert. Dieses Zahngold hat einen silbergrauen Farbton. Fragen Sie Ihren Zahnarzt!',
          'Bayern Gold24 ist auf den Ankauf von <strong>versilberten Bestecken, Messern, Hotelsilber</strong> (z.B. Schalen, Becher, Tabletts, Kerzenhalter) spezialisiert. In Deutschland dominieren 90er oder 100er Versilberungen. Dies sagt aus, dass 90 g bzw. 100 g Feinsilber auf etwa 12 Essgabeln und 12 Löffeln abgeschieden wird. Zur Unterscheidung von echtsilbernen und versilberten Gegenständen, Besteckteilen oder Hotelsilber prüfen wir optisch auf vorhandene Punzen.',
          'Wir geben <strong>Zinn</strong> zurück in den Wertstoffkreislauf. Wir kaufen jegliche Arten von Zinn an: z.B. Geschirrzinn wie Zinnteller, Becher, Krüge und geschmolzene Zinnstangen oder -barren. Wir vergüten Ihnen den reinen Materialwert. Bitte haben Sie Verständnis, dass wir Sammlerwerte nicht berücksichtigen können.',
        ],
      },
    },
  },

  contactSection: {
    title: 'Haben wir Ihr Interesse geweckt?',
    cta: 'So erreichen Sie uns.',
  },

  shopSection: {
    title: 'Unser Shop',
  },

  header: {
    nav: {
      home: 'Startseite',
      purchase: 'Ankauf',
      shop: 'Shop',
      weddingRingConfigurator: 'Trauring-Konfigurator',
      goldAlloys: 'Goldlegierungen',
      contact: 'Kontakt',
      dataProtection: 'Datenschutz',
      imprint: 'Impressum',
    },
  },

  footer: {
    copyright: `© ${new Date().getFullYear()} Bayern-Gold24`,
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  agb: {
    headline: 'AGB',
  },

  consent: {
    headline: 'Cookie-Hinweis',
    text: 'Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden verwendet, um Ihre Website-Erfahrung zu optimieren und Ihnen einen persönlich auf Sie zugeschnittenen Service bereitstellen zu können, sowohl auf dieser Website als auch auf anderen Medienkanälen. Mehr Infos über die von uns eingesetzten Cookies finden Sie in unserer Datenschutzrichtlinie. Bei Ihrem Besuch auf unserer Seite werden Ihre Daten nicht verfolgt. Damit Sie diesen Hinweis aber nicht jedes mal erneut weg klicken müssen wenn Sie auf unserer Seite unterwegs sind, müssen wir dennoch einen kleinen Cookie speichern, damit werden aber keine personenbezogenen Daten von Ihnen assoziert.',
    acceptCTA: 'Akzeptieren',
    onlyEssentialCTA: 'Nur essentielle Cookies',
    declineCTA: 'Alle ablehnen',
  },

  generic: {
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    company: 'Firma',
    position: 'Stelle',
    mail: 'E-Mail',
    phone: 'Telefonnummer',
    password: 'Password',
    message: 'Nachricht',
    gold: 'Gold',
    silver: 'Silber',
    platinum: 'Platin',
    palladium: 'Palladium',
    currency: ' €',
    info: {
      street: 'Friedrich-Ebert-Straße 28',
      postcode: '26316',
      city: 'Varel',
    },
  },

  UI: {
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: '--- bitte wählen ---',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
