import styled from 'styled-components'
import Image from '../../Image/Image'
import Link from '../../Link/Link'

export const StyledHeader = styled.div`
  position: absolute;
  width: 100%;
  height: 120px;
  z-index: 999;
  background-color: ${({ theme }) => theme.color.primary};
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    background-color: transparent;
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const NavItems = styled.div`
  flex: 1 1 0px;
  justify-content: right;
  display: flex;
  gap: 3%;
  margin-right: 100px;
`

export const Item = styled.div`
  margin: 0px 15px;
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: right;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
  }
`
export const MobileItem = styled.div`
  margin: 25px 15px;
  color: ${({ theme }) => theme.color.font.secondary};
  width: 100%;
`

export const StyledToggle = styled.img`
  height: 40px;
  cursor: pointer;
  display: none;
  position: ${({ open }) => (open ? 'fixed' : 'absolute')};
  right: 20px;
  top: 25px;
  height: 30px;
  z-index: 999;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: block;
  }
`

export const Navigation = styled.div`
  padding: 100px 0px;
  background-color: ${({ theme }) => theme.color.backgroundRed};
  z-index: 998;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-content: flex-start;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: block;
  }
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.font.secondary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.size.sm};
  font-family: ${({ active }) => (active ? 'bold' : 'nav')};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    text-align: left;
  }
`

export const Logo = styled(Image)`
  height: 40px;
  cursor: pointer;
`
export const MainLogo = styled(Image)`
  height: 100px;
  cursor: pointer;
  margin-left: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
  }
`
